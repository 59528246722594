.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Scrollbar styling */
/* Default styles for the custom scrollbar */
.scrollStyle:not(:hover)::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollStyle:not(:hover)::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 5px;
}

.scrollStyle:not(:hover)::-webkit-scrollbar-track {
  background-color: #edf2f7;
  border-radius: 5px;
}

/* Scrollbar styling Timeline commented because used in action console timeline
 .scrollTime::-webkit-scrollbar {
  width: 4px;
}

.scrollTime::-webkit-scrollbar-thumb {
  background-color: #1637bc;
  border-radius: 5px;
}

.scrollTime::-webkit-scrollbar-track {
  background-color: #FFFFFF1A;
  border-radius: 5px;
} */

/* Scrollbar styling for all modules */
.scrollWithoutDefault::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.scrollWithoutDefault::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 5px;
}

.scrollWithoutDefault::-webkit-scrollbar-track {
  background-color: #edf2f7;
  border-radius: 5px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner-dot {
  width: 16px;
  height: 16px;
  background-color: #6b7280;
  border-radius: 50%;
  margin: 0 4px;
  animation: spinner-animation 1s linear infinite;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
